import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { getUserInfo, getUserMatchHistory, getUserManagerMatchHistory, getUserFriendlyMatchHistory, getUserMatchDetail } from '../api/requests';
import './UserPage.css';
import TradePage from './UserInfo/TradePage';
import MatchPage from './UserInfo/MatchPage';
import RankerPage from './UserInfo/RankerPage';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Id from '../components/Id';

export default function UserPage() {
  const [user, setUser] = useState(null);
  const [matchnum, setMatchnum] = useState(20);

  const [Official, setOfficial] = useState([]);
  const [OfficialDetails, setOfficialDetails] = useState({});
  
  const [Manager, setManager] = useState([]);
  const [ManagerDetails, setManagerDetails] = useState({});

  const [Friendly, setFriendly] = useState([]);
  const [FriendlyDetails, setFriendlyDetails] = useState({});

  const [isMatchView, setIsMatchView] = useState(true);
  const [isTradeView, setIsTradeView] = useState(false);
  const [isVsRankerView, setIsVsRankerView] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get('q');

  useEffect(() => {
    (async ()=>{    
      try{
        const response = await getUserInfo(userId);
        if (response.data.accessId && response.data.nickname && response.data.level) {
          setUser(response.data);

          const matchResponse1 = await getUserMatchHistory(response.data.accessId, matchnum);
          setOfficial(matchResponse1);
          fetchMatchDetails(matchResponse1);

          const matchResponse2 = await getUserManagerMatchHistory(response.data.accessId, matchnum);
          setManager(matchResponse2);
          fetchMatchDetails(matchResponse2);

          const matchResponse3 = await getUserFriendlyMatchHistory(response.data.accessId, matchnum);
          setFriendly(matchResponse3);
          fetchMatchDetails(matchResponse3);
          
        } else {
          setUser(null);
        }
      } catch(e){
        console.log(e)
      }
    })()
  }, [userId, matchnum]);

  

  const fetchMatchDetails = async (matchIds) => {
    try {
      const promises = matchIds.map((matchId) => getUserMatchDetail(matchId));
      const matchDetailResponses = await Promise.all(promises);
      const updatedMatchDetails = matchIds.reduce((details, matchId, index) => {
        details[matchId] = matchDetailResponses[index];
        return details;
      }, {});
      
      let matchType = matchDetailResponses[0]['matchType'];

      if (matchType === 50) {
        setOfficialDetails(updatedMatchDetails);
        // console.log('should be printed when u have 공식경기 기록', updatedMatchDetails)
      }
      else if (matchType === 52) {
        setManagerDetails(updatedMatchDetails);
        // console.log('should be printed when u have 감독모드 기록', updatedMatchDetails)
      }
      else if (matchType === 60) {
        setFriendlyDetails(updatedMatchDetails);
        // console.log('should be printed when u have 친선경기 기록', updatedMatchDetails)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const moreMatches = async() => {
    if (matchnum < 61) {
      const new_matchnum = matchnum + 10
      setMatchnum(new_matchnum)
    }
  }

  if (!userId || user === null) {
    return (
      <div className='in_case_no_user'>
        <Spinner animation="border" role="status" className='in_case_no_user__text'>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className='user'>
      <div>
        <Id/>
      </div>
      <div className='user__buttons'>
        <button className='button' onClick={() => {
          setIsMatchView(true);
          setIsTradeView(false);
          setIsVsRankerView(false);
        }}>매치 정보</button>
        <button className='button' onClick={() => {
          setIsMatchView(false);
          setIsTradeView(true);
          setIsVsRankerView(false);
        }}>거래 정보</button>
        <button className='button' onClick={() => {
          setIsMatchView(false);
          setIsTradeView(false);
          setIsVsRankerView(true);
        }}>구단 정보</button>
      </div>
      
      <div class='user__content'>
        {isMatchView && (
          <div className="match-view">
            <MatchPage 
              officialMatch = {Official}
              officialMatchDetails = {OfficialDetails}
              managerMatch = {Manager}
              managerMatchDetails = {ManagerDetails}
              friendlyMatch = {Friendly}
              friendlyMatchDetails = {FriendlyDetails}
            />
          </div>
        )}

        {isTradeView && (
          <div className="trade-view">
            <TradePage />
          </div>
        )}

        {isVsRankerView && (
          <div className="ranker-view">
            <RankerPage 
              officialMatch = {Official}
              officialMatchDetails = {OfficialDetails}
              user = {user}
            />
          </div>
        )}

        {isMatchView && (
          <div className='center_button'>
            <Button variant="secondary" size="lg" onClick={moreMatches} className='more__button'>
              더 보기
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}