import { useLocation } from 'react-router-dom';
import React, { useEffect, useState, useCallback } from 'react';
import { getUserInfo, getUserTier } from '../api/requests';
import Button from 'react-bootstrap/Button';
import './Id.css';

export default function Id() {
  const [user, setUser] = useState(null);
  const [tier, setTier] = useState(null);
  const [imageName, setImageName] = useState(null);

  const [isStar1, setIsStar1] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get('q');

  const fetchSearchUser = useCallback(async () => {
    try {
      const response = await getUserInfo(userId);
      if (response.data.accessId && response.data.nickname && response.data.level) {
        setUser(response.data);
        // call getUserTier function and pass accessId as an argument
        const tierResponse = await getUserTier(response.data.accessId);
        setTier(tierResponse);
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error(error);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchSearchUser();
    }
  }, [fetchSearchUser, userId]);

  const tierlogo = useCallback(() => {
    let tiername = 'default';
  
    if (tier && tier[0]) {
      switch (tier[0].division) {
        case 800:
          tiername = 'super_champ';
          break;
        case 900:
          tiername = 'champ';
          break;
        case 1000:
          tiername = 'super_chal';
          break;
        case 1100:
          tiername = 'chal1';
          break;
        case 1200:
          tiername = 'chal2';
          break;
        case 1300:
          tiername = 'chal3';
          break;
        case 2000:
          tiername = 'world1';
          break;
        case 2100:
          tiername = 'world2';
          break;
        case 2200:
          tiername = 'world3';
          break;
        case 2300:
          tiername = 'pro1';
          break;
        case 2400:
          tiername = 'pro2';
          break;
        case 2500:
          tiername = 'pro3';
          break;
        case 2600:
          tiername = 'semi1';
          break;
        case 2700:
          tiername = 'semi2';
          break;
        case 2800:
          tiername = 'semi3';
          break;
        case 2900:
          tiername = 'amateur1';
          break;
        case 3000:
          tiername = 'amateur2';
          break;
        case 3100:
          tiername = 'amateur3';
          break;
        default:
          // Handle other cases or set a default value
          tiername = 'default';
          break;
      }
    }
  
    setImageName(tiername);
  }, [tier]);

  useEffect(() => {
    if (tier !== null) {
      tierlogo();
    }
  }, [tierlogo, tier]);

  const toggleImage = () => {
    // Get bookmarks from local storage and parse it (with a default value of an empty array)
    const bookmarks = JSON.parse(localStorage.getItem('bookmarks') || '[]');
  
    if (bookmarks.includes(userId)) {
      // User is already bookmarked, so remove from bookmarks
      const updatedBookmarks = bookmarks.filter((item) => item !== userId);
      localStorage.setItem('bookmarks', JSON.stringify(updatedBookmarks));
      setIsStar1(true);
    } else {
      // User is not bookmarked, so add to bookmarks
      bookmarks.push(userId);
      localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
      setIsStar1(false);
    }
  };

  useEffect(() => {
    // Update the star state when the component mounts
    const bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || [];
    setIsStar1(!bookmarks.includes(userId));
  }, [userId]);

  return (
    <div className='id-card'>
      <div className='d-flex'>
        <div className='text-light'>
          {imageName && (
            <img
              className='tier_img'
              src={`./images/${imageName}.png`}
              alt='poster'
            />
          )}
        </div>
        <div className='id__text text-light'>
          {user?.level && <p className='id__name1'>Lv.{user.level}</p>}
          {user?.nickname && <p className='id__name2'>{user.nickname}</p>}
          <div className='buttons'>
            <Button
              as="input"
              type="button"
              value="전적 갱신"
              onClick={() => window.location.reload()}
            />
            <button className='favorite__button' onClick={toggleImage}>
              <img src={isStar1 ? 'images/star1.png' : 'images/star2.png'} alt="" style={{ width: '40px', height: '40px' }}/>
            </button>
          </div>
        </div>
      </div>
    </div>
    
  );
}