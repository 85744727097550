import { useLocation } from 'react-router-dom';
import React, { useEffect, useState, useCallback } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import MatchStat from '../../components/MatchStat';
import GameStat from '../../components/GameStat';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';
// import Spinner from 'react-bootstrap/Spinner';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import './MatchPage.css'

export default function MatchPage({ officialMatch, officialMatchDetails, managerMatch, managerMatchDetails, friendlyMatch, friendlyMatchDetails }) {
  const [officialStats, setOfficialStats] = useState([]);
  const [managerStats, setManagerStats] = useState([]);
  const [friendlyStats, setFriendlyStats] = useState([]);
  const [showStates, setShowStates] = useState(officialMatch.map(() => false));
  const [matchType, setMatchType] = useState('official');
  // const [checked, setChecked] = useState(false);
  // const [radioValue, setRadioValue] = useState('official');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get('q');

  useEffect(() => {
    if (Object.keys(officialMatchDetails).length > 0) {
      calculateAveragePassRate(officialMatchDetails);
    }
  
    if (Object.keys(managerMatchDetails).length > 0) {
      calculateAveragePassRate(managerMatchDetails);
    }
  
    if (Object.keys(friendlyMatchDetails).length > 0) {
      calculateAveragePassRate(friendlyMatchDetails);
    }
  }, [officialMatchDetails, managerMatchDetails, friendlyMatchDetails]);

  const calculateAveragePassRate = (matchDetails) => {
    let totalMatches = 0; // 매치 수
    let totalWins = 0; // 승리 수
    let totalPassSuccess = 0; // 패스 성공
    let totalPassTry = 0; // 패스 시도
    let totalGoal = 0; // 골 수
    let totalShoot = 0; // 슈팅 시도
    let totalLongGoal = 0; // 중거리 골
    let totalLongShot = 0; // 중거리 시도
    let possessions = 0; // 점유율 합
    let matchType = 0;

    for (const matchId in matchDetails) {
      const matchDetail = matchDetails[matchId];
      if (matchDetail) {
        matchType = matchDetail['matchType']
      }
      if (matchDetail && matchDetail.matchInfo) {
        matchDetail.matchInfo.forEach((team) => {
          if (team.nickname === userId && team.pass) {
            totalPassSuccess += team.pass.passSuccess;
            totalPassTry += team.pass.passTry;
            totalMatches++;
          }
          if (team.nickname === userId && team.matchDetail.matchResult) {
            if (team.matchDetail.matchResult === '승') {
              totalWins++;
            }
          }
          if (team.nickname === userId && team.matchDetail.possession) {
            possessions += team.matchDetail.possession
          }
          if (team.nickname === userId && team.shoot) {
            totalGoal += team.shoot.goalTotal
            totalShoot += team.shoot.shootTotal
            totalLongShot += team.shoot.shootOutPenalty
            totalLongGoal += team.shoot.goalOutPenalty
          }
        });
      }
    }

    if (totalMatches > 0) {
      const avgPassRate = (totalPassSuccess / totalPassTry) * 100;
      const avgWinRate = (totalWins / totalMatches) * 100;
      const avgPossession = (possessions / totalMatches);
      const avgConversion = (totalGoal / totalShoot) * 100;
      const avgLongGoal = (totalLongGoal / totalShoot) * 100;
      const avgLongShotTake = (totalLongShot / totalShoot) * 100;
      if (matchType === 50) {
        setOfficialStats([matchType,avgPassRate.toFixed(2),avgWinRate.toFixed(2),avgPossession.toFixed(2),avgConversion.toFixed(2),avgLongGoal.toFixed(2),avgLongShotTake.toFixed(2)]);
      }
      else if (matchType === 52) {
        setManagerStats([matchType,avgPassRate.toFixed(2),avgWinRate.toFixed(2),avgPossession.toFixed(2),avgConversion.toFixed(2),avgLongGoal.toFixed(2),avgLongShotTake.toFixed(2)]);
      }
      else if (matchType === 60) {
        setFriendlyStats([matchType,avgPassRate.toFixed(2),avgWinRate.toFixed(2),avgPossession.toFixed(2),avgConversion.toFixed(2),avgLongGoal.toFixed(2),avgLongShotTake.toFixed(2)]);
      }
    } 
  };




  const toggleShow = (index) => {
    const newShowStates = [...showStates];
    newShowStates[index] = !newShowStates[index];
    setShowStates(newShowStates);
  };

  const ColorChoice = (matchType, matchId) => {
    const match = matchType[matchId]
    if (match) {
      for (const team in match.matchInfo) {
        if ( match.matchInfo[team].nickname === userId ) {
          if ( match.matchInfo[team].matchDetail.matchResult === '승' ) {
            return 'win_blue'
          }
          else if ( match.matchInfo[team].matchDetail.matchResult === '패' ) {
            return 'lose_red'
          }
          else if ( match.matchInfo[team].matchDetail.matchResult === '무' ) {
            return 'draw_gray'
          }
        }
      }
    }
  }

  const renderMatches = (matches) => {
    let details = '';
    if (matches === officialMatch) {
      details = officialMatchDetails
    } else if (matches === friendlyMatch) {
      details = friendlyMatchDetails
    } else {
      details = managerMatchDetails
    }
    return (
      <div className='matchlist'>
        {matches.map((matchId, index) => {
          const matchDetail = details[matchId];
          return (
            matchDetail && matchDetail.matchInfo[0] && matchDetail.matchInfo[1] && (
              <Col md={6} className='match__container' key={index}>
                <Button onClick={() => toggleShow(index)} className={ColorChoice(details, matchId)} style={showStates[index] ? {} : { backgroundColor: 'transparent', border: 'none' }}>
                  <div className='result'>
                    <p className='matchbox__text__title'>
                      {matchDetail.matchInfo[0]?.nickname === userId && matchDetail.matchInfo[0]?.matchDetail.matchResult}
                      {matchDetail.matchInfo[1]?.nickname === userId && matchDetail.matchInfo[1]?.matchDetail.matchResult}
                    </p>
                    <p className='matchbox__text__date'>{matchDetail.matchDate.slice(0, 10)}</p>
                  </div>
                  
                  <div className='score'>
                    <p className='matchbox__text__name'>{matchDetail.matchInfo[0]?.nickname}</p>
                    <p className='matchbox__text__score'>{matchDetail.matchInfo[0]?.shoot.goalTotal} - {matchDetail.matchInfo[1]?.shoot.goalTotal}</p>
                    <p className='matchbox__text__name'>{matchDetail.matchInfo[1]?.nickname}</p>
                  </div>
                  
                  <button className="zoom__container">
                    <img src={`/images/zoom.png`} className='arrowdown' alt="arrow_down" />
                  </button>
                </Button>
                {showStates[index] ? (
                  <Toast show={showStates[index]} onClose={() => toggleShow(index)} className='toast_container'>
                    <Toast.Body className='toast__body'>
                      <GameStat
                        matchDetail={matchDetail}
                        user1={matchDetail.matchInfo[0]?.nickname}
                        user2={matchDetail.matchInfo[1]?.nickname}
                        matchType={matchType}
                      />
                    </Toast.Body>
                  </Toast>
                ) : null }
              </Col>
            )
          );
        })}
      </div>
    )
  }

  const handleButtonClicked = () => {
    setShowStates(officialMatch.map(() => false));
  };

  const radios = [
    { name: '공식 경기', value: 'official' },
    { name: '감독 모드', value: 'manager' },
    { name: '친선 경기', value: 'friendly' },
  ];

  return (
    <div>
      <div className='matchstats'>
        <MatchStat Stats={officialStats} userId={userId} gameType={1} />
        <MatchStat Stats={managerStats} userId={userId} gameType={2} />
        <MatchStat Stats={friendlyStats} userId={userId} gameType={3} />
      </div>

      <div className='button_container'>
        <ButtonGroup>
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant={idx % 1 ? 'outline-success' : 'outline-danger'}
              name="radio"
              value={radio.value}
              checked={matchType === radio.value}
              onClick={handleButtonClicked}
              onChange={(e) => setMatchType(e.currentTarget.value)}
              className='togglebutton'
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </div>

      {matchType === 'official' && renderMatches(officialMatch)}
      {matchType === 'manager' && renderMatches(managerMatch)}
      {matchType === 'friendly' && renderMatches(friendlyMatch)}
    </div>
  );
}