import axios from './axios';


export const getUserInfo = async Nickname => {
  const response = await axios.get(`/users?nickname=${Nickname}`);
  return response;
};

export const getUserTier = async accessId => {
  try {
    const response = await axios.get(`/users/${accessId}/maxdivision`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getUserMatchHistory = async (accessId, matchnum) => {
  try {
    const response = await axios.get(`users/${accessId}/matches?matchtype=50&offset=0&limit=${matchnum}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getUserFriendlyMatchHistory = async (accessId, matchnum) => {
  try {
    const response = await axios.get(`users/${accessId}/matches?matchtype=60&offset=0&limit=${matchnum}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getUserManagerMatchHistory = async (accessId, matchnum) => {
  try {
    const response = await axios.get(`users/${accessId}/matches?matchtype=52&offset=0&limit=${matchnum}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getUserMatchDetail = async matchId => {
  try {
    const response = await axios.get(`matches/${matchId}`);
    // console.log(response.data); // log the response data
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getUserTradeHistory = async (accessId, matchnum) => {
  try {
    const response = await axios.get(`/users/${accessId}/markets?tradetype=buy&offset=0&limit=${matchnum}`);
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getUserSellHistory = async (accessId, matchnum) => {
  try {
    const response = await axios.get(`/users/${accessId}/markets?tradetype=sell&offset=0&limit=${matchnum}`);
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getPlayerInfo = async () => {
  try {
    const response = await axios.get("https://static.api.nexon.co.kr/fconline/latest/spid.json");
    // console.log(response.data)
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getRankerInfo = async players => {
  try {
    const response = await axios.get(`/rankers/status?matchtype=50&players=${players}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getGradeInfo = async spid => {
  try {
    const response = await axios.get(`https://static.api.nexon.co.kr/fconline/latest/seasonid.json`);
    // console.log(response.data)
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}