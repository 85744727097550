import React, { useEffect, useState,useCallback } from 'react';
import './TradePage.css';
import { getUserInfo, getUserTradeHistory, getUserSellHistory, getPlayerInfo, getGradeInfo } from '../../api/requests';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

export default function TradePage() {
  const [trade, setTrade] = useState([]);
  const [sell, setSell] = useState([]);
  const [matchnum, setMatchnum] = useState(5);
  
  const userId = new URLSearchParams(window.location.search).get('q');

  const fetchSearchUser =  useCallback(async () => {
    try {
      const response = await getUserInfo(userId);
      if (response.data.accessId && response.data.nickname && response.data.level) {
        const tradeResponse = await getUserTradeHistory(response.data.accessId, matchnum);
        const updatedTrade = await updateTradeItems(tradeResponse);
        const sellResponse = await getUserSellHistory(response.data.accessId, matchnum);
        const updatedSell = await updateTradeItems(sellResponse);
        setTrade(updatedTrade);
        setSell(updatedSell);
      } 
    } catch (error) {
      console.error(error);
    }
  },[userId, matchnum])
   
  useEffect(() => {
    if (userId) {
      fetchSearchUser();
    }
  }, [fetchSearchUser, userId]);

  const updateTradeItems = async (tradeItems) => {
    const updatedTradeItems = [];
    const playerInfo = await getPlayerInfo();
    const playerClass = await getGradeInfo();
    for (const tradeItem of tradeItems) {
      // console.log(tradeItem, tradeItems)
      const playerName = playerInfo.find((player) => player.id === tradeItem.spid)?.name || '';
      const playerSeason = playerClass.find((playerclass) => playerclass.seasonId === Math.floor(tradeItem.spid / 1000000))?.seasonImg||'';
      const updatedTradeItem = { ...tradeItem, playerName, playerSeason };
      // console.log(tradeItem.id, playerSeason)
      updatedTradeItems.push(updatedTradeItem);
    }
    return updatedTradeItems;
  };


  const handleImgError = (e) => {
    const defaultProfile = "/images/unknown.png"
    e.target.src = defaultProfile;
  }

  const moreMatches = async() => {
    if (matchnum < 31) {
      const new_matchnum = matchnum + 5
      setMatchnum(new_matchnum)
    }
  }

  return (
    <div className="center-container">
      <div className="boxes-container">
        <div className="trade__box">
          <h1>구매 목록</h1>
          {trade.length > 0 ? (
            <ul className="trade-list">
            {trade.map((tradeItem) => (
              <div className='playercard' key={tradeItem.saleSn}>
                <div className='imagebox'>
                  <img 
                    src={`https://fo4.dn.nexoncdn.co.kr/live/externalAssets/common/playersAction/p${tradeItem.spid}.png`} 
                    className='playerimg' 
                    alt={"No Player"}
                    onError={handleImgError}
                  />
                </div>
                <div className='playercard__texts'>
                  <li key={`listbox__1-${tradeItem.saleSn}`} className='listbox__1'>
                    <img src={tradeItem.playerSeason} className='seasonimg' alt="No Season" />
                    <p className='trade_name'>{tradeItem.playerName}</p>
                    <img src={`/images/grade${tradeItem.grade}.png`} className='playergrade' alt="No Player Grade" />
                  </li>
                  <li key={`listbox__2-${tradeItem.saleSn}`} className='listbox__2'>
                    <p className='trade_date'>{new Date(tradeItem.tradeDate).toLocaleString()} 구매 완료</p>
                    <p className='trade_price'>{tradeItem.value.toLocaleString()} BP</p>
                  </li>
                </div>
              </div>
            ))}
          </ul>
          ) : (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
        </div>
        <div className="trade__box">
          <h1>판매 목록</h1>
          {sell.length > 0 ? (
            <ul className="trade-list">
              {sell.map((tradeItem) => (
                <div className='playercard' key={tradeItem.saleSn}>
                  <div className='imagebox'>
                    <img 
                      src={`https://fo4.dn.nexoncdn.co.kr/live/externalAssets/common/playersAction/p${tradeItem.spid}.png`} 
                      className='playerimg' 
                      alt="No Player " 
                      onError={handleImgError}
                    />
                  </div>
                  <div className='playercard__texts'>
                    <li key={`listbox__1-${tradeItem.saleSn}`} className='listbox__1'>
                      <img src={tradeItem.playerSeason} className='seasonimg' alt="No Season" />
                      <p className='trade_name'>{tradeItem.playerName}</p>
                      <img src={`/images/grade${tradeItem.grade}.png`} className='playergrade' alt="No Player Grade" />
                    </li>
                    <li key={`listbox__2-${tradeItem.saleSn}`} className='listbox__2'>
                      <p className='trade_date'>{new Date(tradeItem.tradeDate).toLocaleString()} 판매 완료</p>
                      <p className='trade_price'>{tradeItem.value.toLocaleString()} BP</p>
                    </li>
                  </div>
                </div>
              ))}
            </ul>
          ) : (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
        </div>
      </div>
      <div className='button-container'>
        <Button variant="secondary" size="lg" onClick={moreMatches} className='more__button'>
          더 보기
        </Button>
      </div>
    </div>
  );
}