import React from 'react'
import { useEffect, useState, useCallback } from 'react';
import { getUserInfo, getUserTier } from '../api/requests';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import './GameStat.css'

export default function GameStat({ matchDetail, user1, user2, matchType }) {
    const [tier1, setTier1] = useState(null);
    const [tier2, setTier2] = useState(null);
    const [imageName1, setImageName1] = useState(null);
    const [imageName2, setImageName2] = useState(null);
    const [imageName3, setImageName3] = useState(null);
    const [imageName4, setImageName4] = useState(null);

    useEffect(() => {
      if (tier1 !== null) {
        tierlogo(tier1, 1);
      }
      if (tier2 !== null) {
        tierlogo(tier2, 2);
      }
    }, [tier1, tier2]);
  
    const fetchSearchUser = useCallback(async (user) => {
      try {
        const response = await getUserInfo(user);
        if (response.data.accessId && response.data.nickname && response.data.level) {

          // call getUserTier function and pass accessId as an argument
          const tierResponse = await getUserTier(response.data.accessId);
          if (user === user1) {
            setTier1(tierResponse);
          } else {
            setTier2(tierResponse);
          }
          
        } 
      } catch (error) {
        console.error(error);
      }
    }, [user1]);

    useEffect(() => {
        if (user1) {
            fetchSearchUser(user1);
        }  
        if (user2) {
            fetchSearchUser(user2);
        }
      }, [fetchSearchUser, user1, user2]);
  
    const tierlogo = (tier, idx) => {
        let tiername = '';
        console.log(123123)
        for (const num in tier) {
            if (tier && tier[num]) {
                switch (tier[num].division) {
                  case 800:
                    tiername = 'super_champ';
                    break;
                  case 900:
                    tiername = 'champ';
                    break;
                  case 1000:
                    tiername = 'super_chal';
                    break;
                  case 1100:
                    tiername = 'chal1';
                    break;
                  case 1200:
                    tiername = 'chal2';
                    break;
                  case 1300:
                    tiername = 'chal3';
                    break;
                  case 2000:
                    tiername = 'world1';
                    break;
                  case 2100:
                    tiername = 'world2';
                    break;
                  case 2200:
                    tiername = 'world3';
                    break;
                  case 2300:
                    tiername = 'pro1';
                    break;
                  case 2400:
                    tiername = 'pro2';
                    break;
                  case 2500:
                    tiername = 'pro3';
                    break;
                  case 2600:
                    tiername = 'semi1';
                    break;
                  case 2700:
                    tiername = 'semi2';
                    break;
                  case 2800:
                    tiername = 'semi3';
                    break;
                  case 2900:
                    tiername = 'amateur1';
                    break;
                  case 3000:
                    tiername = 'amateur2';
                    break;
                  case 3100:
                    tiername = 'amateur3';
                    break;
                  default:
                    // Handle other cases or set a default value
                    tiername = 'default';
                    break;
                }
              }
            if (num === 0 && idx === 1) {
                console.log(tiername)
                setImageName1(tiername);
            } else if (num === 0 && idx === 2) {
                setImageName2(tiername);
            } else if (num === 1 && idx === 1) {
                setImageName3(tiername);
            } else if (num === 1 && idx === 2) {
                setImageName4(tiername);
            }
        }
      };

    const ColorChoice = (match) => {
        const result = match.matchDetail.matchResult
        if ( result === '승' ) {
        return 'blue'
        }
        else if ( result === '패' ) {
        return 'red'
        }
        else if ( result === '무' ) {
        return 'gray'
        }
      }

    const ImageChoice = (idx) => {
        if (matchType === 'official' && idx === 0) {
            // console.log(imageName1, idx, matchType)
            return imageName1
        } else if (matchType === 'friendly' && idx === 0) {
            return imageName1
        } else if (matchType === 'manager' && idx === 0) {
            return imageName3
        } else if (matchType === 'official' && idx === 1) {
            return imageName2
        } else if (matchType === 'friendly' && idx === 1) {
            return imageName2
        } else if (matchType === 'manager' && idx === 1) {
            return imageName4
        }
    }
    
    return (
        <div className='gamestat__container'>
            <div className={ColorChoice(matchDetail.matchInfo[0])}>
                <div className='user__name'>
                    <img src={`./images/${ImageChoice(0)}.png`} alt="" className='user__name__2__img'/>
                    <a href={`/user?q=${matchDetail.matchInfo[0].nickname}`} className='user__name__nickname'>{matchDetail.matchInfo[0].nickname}</a>
                </div>
                <div className='stats'>
                    <div className='stats__bars'>
                        <div className='stats__bars__bar'>
                            점유율
                            {matchDetail.matchInfo[0].matchDetail.possession > 0? (
                                <CircularProgressbar value={matchDetail.matchInfo[0].matchDetail.possession} text={`${(matchDetail.matchInfo[0].matchDetail.possession)}%`} className='progressbar' styles={buildStyles({pathColor: `#fff685`, trailColor: `darkgray`})}/>
                            ):(<CircularProgressbar value={0} text={`0%`} className='progressbar' styles={buildStyles({pathColor: `#fff685`, trailColor: `darkgray`})}/>)}  
                        </div>
                        <div className='stats__bars__bar'>
                            패스 성공률
                            {matchDetail.matchInfo[0].pass.passTry > 0? (
                                <CircularProgressbar value={matchDetail.matchInfo[0].pass.passSuccess / matchDetail.matchInfo[0].pass.passTry * 100} text={`${(matchDetail.matchInfo[0].pass.passSuccess / matchDetail.matchInfo[0].pass.passTry * 100).toFixed(2)}%`} className='progressbar' styles={buildStyles({pathColor: `#fff685`, trailColor: `darkgray`})}/>
                            ):(<CircularProgressbar value={0} text={`0%`} className='progressbar' styles={buildStyles({pathColor: `#fff685`, trailColor: `darkgray`})}/>)}
                        </div>
                        <div className='stats__bars__bar'>
                            태클 성공률
                            {matchDetail.matchInfo[0].defence.tackleTry > 0? (
                                <CircularProgressbar value={matchDetail.matchInfo[0].defence.tackleSuccess / matchDetail.matchInfo[0].defence.tackleTry * 100} text={`${(matchDetail.matchInfo[0].defence.tackleSuccess / matchDetail.matchInfo[0].defence.tackleTry * 100).toFixed(2)}%`} className='progressbar' styles={buildStyles({pathColor: `#fff685`, trailColor: `darkgray`})}/>
                            ):(<CircularProgressbar value={0} text={`0%`} className='progressbar' styles={buildStyles({pathColor: `#fff685`, trailColor: `darkgray`})}/>)}
                        </div>
                        <div className='stats__bars__bar'>
                            슈팅 성공률
                            {matchDetail.matchInfo[0].shoot.shootTotal > 0? (
                                <CircularProgressbar value={matchDetail.matchInfo[0].shoot.goalTotal / matchDetail.matchInfo[0].shoot.shootTotal * 100} text={`${(matchDetail.matchInfo[0].shoot.goalTotal / matchDetail.matchInfo[0].shoot.shootTotal * 100).toFixed(2)}%`} className='progressbar' styles={buildStyles({pathColor: `#fff685`, trailColor: `darkgray`})}/>
                            ):(<CircularProgressbar value={0} text={`0%`} className='progressbar' styles={buildStyles({pathColor: `#fff685`, trailColor: `darkgray`})}/>)}
                        </div>
                    </div>
                    <div className='stats__bars__stat'>
                        <div className='stats__bars__stat__box'>
                            <div className='stat__box__1'>
                                경기 결과
                            </div>
                            <div className='stat__box__2'></div>
                            <div className='stat__box__3'>
                                {matchDetail.matchInfo[0].matchDetail.matchResult}
                            </div>
                        </div>
                        <div className='stats__bars__stat__box'>
                            <div className='stat__box__1'>
                                평균 평점
                            </div>
                            <div className='stat__box__2'></div>
                            <div className='stat__box__3'>
                                {matchDetail.matchInfo[0].matchDetail.averageRating.toFixed(2)}
                            </div>
                        </div>
                        <div className='stats__bars__stat__box'>
                            <div className='stat__box__1'>
                                골 수
                            </div>
                            <div className='stat__box__2'></div>
                            <div className='stat__box__3'>
                                {matchDetail.matchInfo[0].shoot.goalTotal}
                            </div>
                        </div>
                        <div className='stats__bars__stat__box'>
                            <div className='stat__box__1'>
                                슈팅 수
                            </div>
                            <div className='stat__box__2'></div>
                            <div className='stat__box__3'>
                                {matchDetail.matchInfo[0].shoot.shootTotal}
                            </div>
                        </div>
                    </div>
                    <div className='stats__bars__stat'>
                        <div className='stats__bars__stat__box'>
                            <div className='stat__box__1'>
                                스루 패스
                            </div>
                            <div className='stat__box__2'></div>
                            <div className='stat__box__3'>
                                {matchDetail.matchInfo[0].pass.throughPassTry}회
                            </div>
                        </div>
                        <div className='stats__bars__stat__box'>
                            <div className='stat__box__1'>
                                태클 성공
                            </div>
                            <div className='stat__box__2'></div>
                            <div className='stat__box__3'>
                                {matchDetail.matchInfo[0].defence.tackleSuccess}회
                            </div>
                        </div>
                        <div className='stats__bars__stat__box'>
                            <div className='stat__box__1'>
                                헤딩 골
                            </div>
                            <div className='stat__box__2'></div>
                            <div className='stat__box__3'>
                                {matchDetail.matchInfo[0].shoot.goalHeading}
                            </div>
                        </div>
                        <div className='stats__bars__stat__box'>
                            <div className='stat__box__1'>
                                중거리 골
                            </div>
                            <div className='stat__box__2'></div>
                            <div className='stat__box__3'>
                                {matchDetail.matchInfo[0].shoot.goalOutPenalty}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={ColorChoice(matchDetail.matchInfo[1])}>
            <div className='user__name'>
                    <img src={`./images/${ImageChoice(1)}.png`} alt="" className='user__name__2__img'/>
                    <a href={`/user?q=${matchDetail.matchInfo[1].nickname}`} className='user__name__nickname'>{matchDetail.matchInfo[1].nickname}</a>
                </div>
                <div className='stats'>
                    <div className='stats__bars'>
                        <div className='stats__bars__bar'>
                            점유율
                            {matchDetail.matchInfo[1].matchDetail.possession > 0? (
                                <CircularProgressbar value={matchDetail.matchInfo[1].matchDetail.possession} text={`${(matchDetail.matchInfo[1].matchDetail.possession)}%`} className='progressbar' styles={buildStyles({pathColor: `#fff685`, trailColor: `darkgray`})}/>
                            ):(<CircularProgressbar value={0} text={`0%`} className='progressbar' styles={buildStyles({pathColor: `#fff685`, trailColor: `darkgray`})}/>)}  
                        </div>
                        <div className='stats__bars__bar'>
                            패스 성공률
                            {matchDetail.matchInfo[1].pass.passTry > 0? (
                                <CircularProgressbar value={matchDetail.matchInfo[1].pass.passSuccess / matchDetail.matchInfo[1].pass.passTry * 100} text={`${(matchDetail.matchInfo[1].pass.passSuccess / matchDetail.matchInfo[1].pass.passTry * 100).toFixed(2)}%`} className='progressbar' styles={buildStyles({pathColor: `#fff685`, trailColor: `darkgray`})}/>
                            ):(<CircularProgressbar value={0} text={`0%`} className='progressbar' styles={buildStyles({pathColor: `#fff685`, trailColor: `darkgray`})}/>)}
                        </div>
                        <div className='stats__bars__bar'>
                            태클 성공률
                            {matchDetail.matchInfo[1].defence.tackleTry > 0? (
                                <CircularProgressbar value={matchDetail.matchInfo[1].defence.tackleSuccess / matchDetail.matchInfo[1].defence.tackleTry * 100} text={`${(matchDetail.matchInfo[1].defence.tackleSuccess / matchDetail.matchInfo[1].defence.tackleTry * 100).toFixed(2)}%`} className='progressbar' styles={buildStyles({pathColor: `#fff685`, trailColor: `darkgray`})}/>
                            ):(<CircularProgressbar value={0} text={`0%`} className='progressbar' styles={buildStyles({pathColor: `#fff685`, trailColor: `darkgray`})}/>)}
                        </div>
                        <div className='stats__bars__bar'>
                            슈팅 성공률
                            {matchDetail.matchInfo[1].shoot.shootTotal > 0? (
                                <CircularProgressbar value={matchDetail.matchInfo[1].shoot.goalTotal / matchDetail.matchInfo[1].shoot.shootTotal * 100} text={`${(matchDetail.matchInfo[1].shoot.goalTotal / matchDetail.matchInfo[1].shoot.shootTotal * 100).toFixed(2)}%`} className='progressbar' styles={buildStyles({pathColor: `#fff685`, trailColor: `darkgray`})}/>
                            ):(<CircularProgressbar value={0} text={`0%`} className='progressbar' styles={buildStyles({pathColor: `#fff685`, trailColor: `darkgray`})}/>)}
                        </div>
                    </div>
                    <div className='stats__bars__stat'>
                        <div className='stats__bars__stat__box'>
                            <div className='stat__box__1'>
                                경기 결과
                            </div>
                            <div className='stat__box__2'></div>
                            <div className='stat__box__3'>
                                {matchDetail.matchInfo[1].matchDetail.matchResult}
                            </div>
                        </div>
                        <div className='stats__bars__stat__box'>
                            <div className='stat__box__1'>
                                평균 평점
                            </div>
                            <div className='stat__box__2'></div>
                            <div className='stat__box__3'>
                                {matchDetail.matchInfo[1].matchDetail.averageRating.toFixed(2)}
                            </div>
                        </div>
                        <div className='stats__bars__stat__box'>
                            <div className='stat__box__1'>
                                골 수
                            </div>
                            <div className='stat__box__2'></div>
                            <div className='stat__box__3'>
                                {matchDetail.matchInfo[1].shoot.goalTotal}
                            </div>
                        </div>
                        <div className='stats__bars__stat__box'>
                            <div className='stat__box__1'>
                                슈팅 수
                            </div>
                            <div className='stat__box__2'></div>
                            <div className='stat__box__3'>
                                {matchDetail.matchInfo[1].shoot.shootTotal}
                            </div>
                        </div>
                    </div>
                    <div className='stats__bars__stat'>
                        <div className='stats__bars__stat__box'>
                            <div className='stat__box__1'>
                                스루 패스
                            </div>
                            <div className='stat__box__2'></div>
                            <div className='stat__box__3'>
                                {matchDetail.matchInfo[1].pass.throughPassTry}회
                            </div>
                        </div>
                        <div className='stats__bars__stat__box'>
                            <div className='stat__box__1'>
                                태클 성공
                            </div>
                            <div className='stat__box__2'></div>
                            <div className='stat__box__3'>
                                {matchDetail.matchInfo[1].defence.tackleSuccess}회
                            </div>
                        </div>
                        <div className='stats__bars__stat__box'>
                            <div className='stat__box__1'>
                                헤딩 골
                            </div>
                            <div className='stat__box__2'></div>
                            <div className='stat__box__3'>
                                {matchDetail.matchInfo[1].shoot.goalHeading}
                            </div>
                        </div>
                        <div className='stats__bars__stat__box'>
                            <div className='stat__box__1'>
                                중거리 골
                            </div>
                            <div className='stat__box__2'></div>
                            <div className='stat__box__3'>
                                {matchDetail.matchInfo[1].shoot.goalOutPenalty}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
    );
  }
