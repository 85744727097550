import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://api.nexon.co.kr/fifaonline4/v1.0',
  headers: {
    Authorization: process.env.REACT_APP_FIFA_KEY,
  },
});


export default instance;