import React, { useEffect, useState, useCallback } from 'react';
import { getUserInfo, getUserTier} from '../api/requests';
import Spinner from 'react-bootstrap/Spinner';
import './Favorites.css'

export default function Favorites() {
  const [favoriteUsers, setFavoriteUsers] = useState([]);
  const [tier, setTier] = useState([]);
  const [imageName, setImageName] = useState(null);

  console.log(tier, imageName)

  useEffect(() => {
      fetchBookmarks();
  }, []);

  const fetchBookmarks = () => {
    const bookmark = localStorage.getItem('bookmarks');
    
    // Parse the JSON string from local storage and set it as an array
    const parsedBookmarks = JSON.parse(bookmark) || [];

    setFavoriteUsers(parsedBookmarks);
  }

  const fetchSearchUser = useCallback(async () => {
    try {
      const tiers = [];
      for (const idx in favoriteUsers) {
        const response = await getUserInfo(favoriteUsers[idx]);
        if (response.data.accessId && response.data.nickname && response.data.level) {
          const tierResponse = await getUserTier(response.data.accessId);
          tiers.push(tierResponse);
        } 
      }
      setTier(tiers)
    } catch (error) {
      console.error(error);
    }
  }, [favoriteUsers]);

  useEffect(() => {
    fetchSearchUser();
  }, [fetchSearchUser, favoriteUsers]);

  const tierlogo = useCallback(() => {
    const imageName = [];
    
    for (const idx in tier) {
      let tiername = '';
      if (tier[idx] && tier[idx][0]) {
        switch (tier[idx][0].division) {
          case 800:
            tiername = 'super_champ';
            break;
          case 900:
            tiername = 'champ';
            break;
          case 1000:
            tiername = 'super_chal';
            break;
          case 1100:
            tiername = 'chal1';
            break;
          case 1200:
            tiername = 'chal2';
            break;
          case 1300:
            tiername = 'chal3';
            break;
          case 2000:
            tiername = 'world1';
            break;
          case 2100:
            tiername = 'world2';
            break;
          case 2200:
            tiername = 'world3';
            break;
          case 2300:
            tiername = 'pro1';
            break;
          case 2400:
            tiername = 'pro2';
            break;
          case 2500:
            tiername = 'pro3';
            break;
          case 2600:
            tiername = 'semi1';
            break;
          case 2700:
            tiername = 'semi2';
            break;
          case 2800:
            tiername = 'semi3';
            break;
          case 2900:
            tiername = 'amateur1';
            break;
          case 3000:
            tiername = 'amateur2';
            break;
          case 3100:
            tiername = 'amateur3';
            break;
          default:
            // Handle other cases or set a default value
            tiername = 'default';
            break;
        } 
    } else {
      tiername = 'default';
    }
    imageName.push(tiername)
    }
    
    setImageName(imageName);
  }, [tier]);

  useEffect(() => {
    tierlogo();
  }, [tierlogo, tier]);


  return (
    <div className='favorites_container'>
      <div className='favorites__title'>
        즐겨찾기 유저
      </div>
      {favoriteUsers.length > 0 ? (
        <ul className="favorites_list">
          {favoriteUsers.map((user, idx) => (
            <a href={`/user?q=${user}`} className='favorites_list__usercard' >
              <img
                className='favorites_list__usercard__tier_img'
                src={`./images/${imageName[idx]}.png`}
                alt=''
              />
              <p className='favorites_list__usercard__username'>{user}</p>
            </a>
          ))}
        </ul>
      ) : (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </div>
  )
}
