import React from "react";
import "./Nav.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

export default function Navigation() {
  
    return (
      <Navbar bg="primary" expand="lg" variant="light" className="m-0 p-0 nav">
        <Container fluid className="m-0 p-0 bg-dark nav__container">
          <Navbar.Brand href="/" className="m-4 text-light fs-10 bg-dark nav__brand">
            <Nav.Link href="/" className="text-light"><img src="./images/fos.png" alt="" className="nav__logo"/></Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav" className="me-auto bg-dark">
            <Nav className="me-auto bg-dark nav__links">
              <Nav.Link href="/" className="text-light">Home</Nav.Link>
              <Nav.Link href="Favorites" className="text-light">즐겨 찾기</Nav.Link>
              <Nav.Link href="Inquiries" className="text-light">문의</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }