import React, { useState }  from 'react';
import { useNavigate } from 'react-router-dom';
import './Search.css';

export default function Search() {
  const [nickname, setNickname] = useState('');
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/user?q=${nickname}`);
  };

  const handleNicknameChange = (event) => {
    setNickname(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();// Trigger the button click event when the spacebar is pressed
      handleClick();
    }
  };

  return (
    <div className='search'>
      <div className=''>
        <img src="./images/logo_nobackground.png" alt="" className='main_logo_img'/>
      </div>
      <div>
        <input
            type='text'
            value={nickname}
            placeholder='감독명을 입력하세요'
            onChange={handleNicknameChange}
            onKeyPress={handleKeyPress}
            className='search__input m-2'
            autoFocus 
          />
        <button onClick={handleClick} className='search__button'>
          검색
        </button>
      </div>
    </div>
  );
}