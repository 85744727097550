import React, { useEffect, useState, useCallback } from 'react'
import { getPlayerInfo, getGradeInfo } from '../api/requests';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import './Player.css'

export default function Player({ myStats, rankerStats, myplayer, matchnums }) {
  const [showStates, setShowStates] = useState(myStats.map(() => false));
  const [playerNames, setPlayerNames] = useState([]);
  const [players, setPlayers] = useState([]);
  // console.log(playerNames)
  console.log(myplayer, matchnums, myStats, rankerStats)
  console.log(players)

  const getPlayers = async () => {
    const playerinfos = await getPlayerInfo();
    setPlayers(playerinfos)
  }

  useEffect(() =>{
    getPlayers();
  }, [])

  const getPlayerName = useCallback(async () => {
    const playernames = [];
    const playerlist = players
    // const players = await getPlayerInfo();
    for (const player in myStats) {
      const playerClass = await getGradeInfo();
      const playerName = playerlist.find((p) => p.id === myStats[player].spId)?.name || '';
      const playerSeason = playerClass.find((playerclass) => playerclass.seasonId === Math.floor(myStats[player].spId / 1000000))?.seasonImg||'';
      myStats[player]['playerSeason'] = playerSeason
      playernames.push(playerName)
    }
    setPlayerNames(playernames)
  }, [myStats, players]);

  useEffect(() => {
    if (myStats.length > 0) {
      getPlayerName()
    }
  }, [getPlayerName, myStats])

  const toggleShow = (index) => {
    const newShowStates = [...showStates];
    newShowStates[index] = !newShowStates[index];
    setShowStates(newShowStates);
  };

  const getButtonVariant = (spPosition) => {
    if (spPosition === 0) {
      return 'warning'; 
    } else if (spPosition < 9) {
      return 'primary'; 
    } else if (spPosition < 20) {
      return 'success'; 
    } else if (spPosition < 28) {
      return 'danger'; 
    } else {
      return 'secondary'; 
    }
  };

  const getPosition = (spposition) => {
    const descriptions = [
      "GK", "SW", "RWB", "RB", "RCB", "CB", "LCB", "LB",
      "LWB", "RDM", "CDM", "LDM", "RM", "RCM", "CM", "LCM",
      "LM", "RAM", "CAM", "LAM", "RF", "CF", "LF", "RW", "RS",
      "ST", "LS", "LW", "SUB"
    ];
  
    if (spposition >= 0 && spposition < descriptions.length) {
      return descriptions[spposition];
    } else {
      return "Unknown"; // Or handle the case for an invalid spposition
    }
  }

  const renderStatItem = (label, myValue, rankerValue) => (
    <div className='stat__row'>
      <div className='stat__stats'>{myValue !== undefined ? myValue : '0'}</div>
      <div className='stat__name'>{label}</div>
      <div className='stat__stats'>{rankerValue}</div>
    </div>
  );

  return (
      <div>
        <ul className='player-row'>
          {myStats.map((player, index) => (
            <Col md={6} className="playerbox" key={index}>
              <Button variant={getButtonVariant(player.spPosition)} onClick={() => toggleShow(index)} className="playerinfo d-flex align-items-center">
                <p className='playername__playerposition'>{getPosition(player.spPosition)}</p>
                <div className="playername">
                  <img src={player.playerSeason} className='seasonimg' alt="" />
                  {playerNames[index] ? (
                    <p className='playername__playernames'>{playerNames[index]}</p>
                  ) : (
                    <Spinner animation="grow" variant="light" />
                  )}
                  <img src={`/images/grade${player.spGrade}.png`} className='playergrade' alt="" />
                </div>
                <div>
                {/* <img src={`/images/arrow_down.png`} className='arrowdown' alt="arrow_down" /> */}
                </div>
              </Button>
              {showStates[index] ? (
                <Toast show={showStates[index]} onClose={() => toggleShow(index)} className="toastbox">
                  <Toast.Body className="toastbox d-flex flex-column justify-content-center align-items-center"> 
                    <div className='toast_header d-flex justify-content-center align-items-center'>
                      <div className='toast_explanation'>
                        <p className='toast_explanation__1'>내가 활용한</p>
                        <p className='toast_explanation__2'>
                          <img src={player.playerSeason} className='toast_explanation__2__img' alt="" />
                          {playerNames[index]}
                        </p>
                        <p className='toast_explanation__3'>경기 집계 : 최근 {matchnums} 경기</p>
                      </div>
                      <Col xs={5} md={2} className="bg-color">
                        <Row className="bg-color">
                          <Image
                            src={`https://fo4.dn.nexoncdn.co.kr/live/externalAssets/common/playersAction/p${myStats[index].spId}.png`} 
                            className='playerimg' 
                            alt="No Player Image" 
                            roundedCircle
                          />
                        </Row>
                      </Col>
                      <div className='toast_explanation'>
                        <p className='toast_explanation__1'>랭커들이 활용한</p>
                        <p className='toast_explanation__2'>
                          <img src={player.playerSeason} className='toast_explanation__2__img' alt="" />
                          {playerNames[index]}
                        </p>
                        <p className='toast_explanation__3'>경기 집계 : 최근 {rankerStats[index].status['matchCount']} 경기</p>
                      </div>
                    </div>
                    <div className="">
                      {renderStatItem('경기당 골', myplayer[index]?.goal, rankerStats[index].status['goal'])}
                      {renderStatItem('경기당 어시스트', myplayer[index]?.assist, rankerStats[index].status['assist'])}
                      {renderStatItem('경기당 유효 슈팅', myplayer[index]?.effectiveShoot, rankerStats[index].status['effectiveShoot'])}
                      {renderStatItem('경기당 패스 시도', myplayer[index]?.passTry, rankerStats[index].status['passTry'])}
                      {renderStatItem('경기당 패스 성공', myplayer[index]?.passSuccess, rankerStats[index].status['passSuccess'])}
                      {renderStatItem('경기당 드리블 시도', myplayer[index]?.dribbleTry, rankerStats[index].status['dribbleTry'])}
                      {renderStatItem('경기당 드리블 성공', myplayer[index]?.dribbleSuccess, rankerStats[index].status['dribbleSuccess'])}
                      {renderStatItem('경기당 볼 터치', myplayer[index]?.dribble, rankerStats[index].status['dribble'])}
                      {renderStatItem('경기당 태클', myplayer[index]?.tackle, rankerStats[index].status['tackle'])}
                      {renderStatItem('경기당 블록', myplayer[index]?.block, rankerStats[index].status['block'])}
                    </div>
                  </Toast.Body>
                </Toast>
              ) : null}
            <div>
              {index === 10 ? <hr className="line-inside-div" /> : null}
            </div>
            </Col>
          ))}
        </ul>
      </div>
    );
  }