import React from 'react'
import { useEffect, useState, useCallback } from 'react';
import { getUserInfo, getUserTier } from '../api/requests';
import ProgressBar from 'react-bootstrap/ProgressBar';
// import Button from 'react-bootstrap/Button';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import './MatchStat.css'

export default function MatchStat({ Stats, userId, gameType }) {
    const [tier, setTier] = useState(null);
    const [tierName, setTierName] = useState(null);
    const [gameName , setGameName] = useState(null);
    const [imageName, setImageName] = useState(null);
    console.log(tier, tierName, 33, Stats)
  
    const fetchSearchUser = useCallback(async () => {
      try {
        const response = await getUserInfo(userId);
        if (response.data.accessId && response.data.nickname && response.data.level) {

          // call getUserTier function and pass accessId as an argument
          const tierResponse = await getUserTier(response.data.accessId);
          setTier(tierResponse);
        } 
      } catch (error) {
        console.error(error);
      }
    }, [userId]);

    useEffect(() => {
      if (userId) {
        fetchSearchUser();
      }  
    }, [fetchSearchUser, userId]);
  
    const tierlogo = useCallback(() => {
      let imageName = '';
      let tierName = '';
      let division = 0;

      if (gameType === 1) {
        setGameName('공식 경기')
        if (tier[0]) {
          division = tier[0]['division']
        }
      }
      else if (gameType === 2) {
        setGameName('감독 모드')
        if (tier[1]) {
          division = tier[1]['division']
        }
      }
      else {
        setGameName('친선 경기')
      }
    
      if (tier) {
        switch (division) {
          case 800:
            tierName = '슈퍼 챔피언스'
            imageName = 'super_champ';
            break;
          case 900:
            tierName = '챔피언스'
            imageName = 'champ';
            break;
          case 1000:
            tierName = '슈퍼 챌린저'
            imageName = 'super_chal';
            break;
          case 1100:
            tierName = '챌린저 1'
            imageName = 'chal1';
            break;
          case 1200:
            tierName = '챌린저 2'
            imageName = 'chal2';
            break;
          case 1300:
            tierName = '챌린저 3'
            imageName = 'chal3';
            break;
          case 2000:
            tierName = '월드클래스 1'
            imageName = 'world1';
            break;
          case 2100:
            tierName = '월드클래스 2'
            imageName = 'world2';
            break;
          case 2200:
            tierName = '월드클래스 3'
            imageName = 'world3';
            break;
          case 2300:
            tierName = '프로 1'
            imageName = 'pro1';
            break;
          case 2400:
            tierName = '프로 2'
            imageName = 'pro2';
            break;
          case 2500:
            tierName = '프로 3'
            imageName = 'pro3';
            break;
          case 2600:
            tierName = '세미프로 1'
            imageName = 'semi1';
            break;
          case 2700:
            tierName = '세미프로 2'
            imageName = 'semi2';
            break;
          case 2800:
            tierName = '세미프로 3'
            imageName = 'semi3';
            break;
          case 2900:
            tierName = '아마추어 1'
            imageName = 'amateur1';
            break;
          case 3000:
            tierName = '아마추어 2'
            imageName = 'amateur2';
            break;
          case 3100:
            tierName = '아마추어 3'
            imageName = 'amateur3';
            break;
          default:
            // Handle other cases or set a default value
            imageName = 'default';
            break;
        }
      }
      setTierName(tierName);
      setImageName(imageName);
    }, [tier, gameType]);

    useEffect(() => {
      if (tier !== null) {
        tierlogo();
      }
    }, [tierlogo, tier]);

    const tiercolor = (tierName) => {
      if (tierName === '아마추어 1' || tierName === '아마추어 2' || tierName === '아마추어 3') {
        return 'p__content__bronze p__content__tiername';
      } else if (tierName === '세미프로 1' || tierName === '세미프로 2' || tierName === '세미프로 3') {
        return 'p__content__gray p__content__tiername';
      } else if (tierName === '프로 1' || tierName === '프로 2' || tierName === '프로 3') {
        return 'p__content__yellow p__content__tiername';
      } else if (tierName === '월드클래스 1' || tierName === '월드클래스 2' || tierName === '월드클래스 3') {
        return 'p__content__purple p__content__tiername';
      } else if (tierName === '챌린저 1' || tierName === '챌린저 2' || tierName === '챌린저 3' || tierName === '슈퍼 챌린저') {
        return 'p__content__green p__content__tiername';
      } else if (tierName === '챔피언스') {
        return 'p__content__red p__content__tiername';
      } else if (tierName === '슈퍼 챔피언스') {
        return 'p__content__pink p__content__tiername';
      }
    }

    // const renderTooltip = (props) => (
    //   <Tooltip id="button-tooltip" {...props}>
    //     Simple tooltip
    //   </Tooltip>
    // );
    
    return (
      <div className="matchinfo">
          <div className="matchtitle">
            {gameName}
          </div>
          <div className="userinfo">
            {imageName && (
              <img
                className="tier_img_1"
                src={`./images/${imageName}.png`}
                alt="poster"
              />
            )}
            <div className="userinfo__content">
              <div className={tiercolor(tierName)}>{tierName}</div>
              {/* <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                className="userinfo__overlay"
                overlay={renderTooltip}>
                <Button variant="success" className="userinfo__overlay">i</Button>
              </OverlayTrigger> */}
              <div className="p__content">
                <p className='p__content__title'>승률</p> 
                <p className='p__content__content'>{Stats[2] !== undefined ? `${Stats[2]}%` : "-"}</p>
              </div>
              <div className="p__content">
                <p className='p__content__title'>중거리 골</p>
                <p className='p__content__content'>{Stats[5] !== undefined ? `${Stats[5]}%` : "-"}</p>
              </div>
            </div>
          </div>
          <div className="gameinfo">
            <div className="infobox">
              점유율
              <ProgressBar now={Stats[3]} className="matchbar" />
              {Stats[3] !== undefined ? `${Stats[3]}%` : "-"}
            </div>
            <div className="infobox">
              슈팅 성공률
              <ProgressBar now={Stats[4]} className="matchbar" />
              {Stats[4] !== undefined ? `${Stats[4]}%` : "-"}
            </div>
          </div>
          <div className="gameinfo">
            <div className="infobox">
              중거리 슛 시도율
              <ProgressBar now={Stats[6]} className="matchbar" />
              {Stats[6] !== undefined ? `${Stats[6]}%` : "-"}
            </div>
            <div className="infobox">
              패스 성공률
              <ProgressBar now={Stats[1]} className="matchbar" />
              {Stats[1] !== undefined ? `${Stats[1]}%` : "-"}
            </div>
          </div>
          
      </div>
    );
  }
