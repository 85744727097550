import React, { useEffect, useState, useCallback } from 'react';
import Player from '../../components/Player';
import axios from 'axios';

export default function RankerPage( {officialMatch, officialMatchDetails, user}) {
  const [myStats, setMyStats] = useState([]);
  const [rankerStats, setRankerStats] = useState([]);
  const [myPlayerStats, setMyPlayerStats] = useState([]);
  // console.log(myStats, rankerStats, officialMatch, 11, officialMatchDetails)
  
  const fetchMyStats = useCallback(async () => {
    if (officialMatch && officialMatch.length > 0 && officialMatchDetails && user) {
      const firstMatchId = officialMatch[0];
      const mystat = officialMatchDetails[firstMatchId];
      console.log(mystat)
      const matchInfo = mystat.matchInfo;
      // console.log("mystat:", mystat);
      let playerInfo = [];
      if (matchInfo[0].nickname === user.nickname) {
        playerInfo = matchInfo[0].player
      }
      else if (matchInfo[1].nickname === user.nickname) {
        playerInfo = matchInfo[1].player
      }
      // console.log(matchInfo, playerInfo)
      playerInfo.sort((a,b) => {
        if (a.spPosition < b.spPosition) {
          return -1;
        }
        if (a.spPosition > b.spPosition) {
          return 1;
        }
        return 0;
      });
      setMyStats(playerInfo)
      
    };
  }, [officialMatch, officialMatchDetails, user]);

  useEffect(() => {
    fetchMyStats();
  }, [fetchMyStats]);

  const getRankerInfo = async (matchtype, players) => {
    try {
      const response = await axios.get(
        `https://public.api.nexon.com/openapi/fconline/v1.0/rankers/status?matchtype=${matchtype}&players=${JSON.stringify(players)}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_FIFA_KEY,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  const fetchRankerStats = useCallback(async () => {
    const allplayers = myStats.map((item) => {
      const { spId, spPosition } = item;
      return { id: spId, po: spPosition };
    });
  

    const matchtype = 50;
    const rankerinfo = await getRankerInfo(matchtype, allplayers);
    rankerinfo.sort((a,b) => {
      if (a.spPosition < b.spPosition) {
        return -1;
      }
      if (a.spPosition > b.spPosition) {
        return 1;
      }
      return 0;
    });
    setRankerStats(rankerinfo)
  }, [myStats]);

  useEffect(() => {
    if (myStats.length > 0) {
      fetchRankerStats();
    }
  }, [fetchRankerStats, myStats]);

  const fetchMyPlayer = useCallback(async () => {
    const playerinfo = [];
  
    for (const myStat of myStats) {
      const spIdToFind = myStat.spId;
      const stat = {
        assist: 0,
        shoot: 0,
        goal: 0,
        dribble: 0,
        dribbleTry: 0,
        dribbleSuccess: 0,
        ballPossesionSuccess: 0,
        passTry: 0,
        passSuccess: 0,
        effectiveShoot: 0,
        spRating: 0,
        block:0,
        tackle:0,
      };
  
      for (const matchId in officialMatchDetails) {
        const matchDetail = officialMatchDetails[matchId];
        for (const team of matchDetail.matchInfo) {
          if (team.nickname === user.nickname && team.player) {
            for (const player of team.player) {
              if (player.spId === spIdToFind) {
                for (const key in stat) {
                  stat[key] += player.status[key];
                }
              }
            }
          }
        }
      }
      const numProperties = Object.keys(officialMatchDetails).length;
      // console.log(stat)
      for (const key in stat) {
        if (numProperties !== 0){
          stat[key] /= numProperties;
          stat[key] = stat[key].toFixed(2)
        }
      }
      playerinfo.push(stat);
      // console.log(stat)
    }
    setMyPlayerStats(playerinfo)
    // console.log(playerinfo)
  }, [officialMatchDetails, user, myStats]);

  useEffect(() => {
    if (myStats.length > 0) {
      fetchMyPlayer();
    }
  }, [fetchMyPlayer, myStats]);

  return (
    <div className='ranker__playercard'>
      <div>
        <Player 
          myStats = {myStats}
          rankerStats = {rankerStats}
          myplayer = {myPlayerStats}
          matchnums = {Object.keys(officialMatchDetails).length}
        />
      </div>
    </div>
  );
}