import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import Navigation from './components/Nav';
import Search from './components/Search';
import UserPage from './pages/UserPage';
import Inquiries from './pages/Inquiries';
import Favorites from './pages/Favorites';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


const Layout = () => {
  return (
    <div>
      <Navigation />
      <Outlet />
    </div>
  );
};

function App() {
  return (
    // <div className='App' style={{ backgroundColor: 'rgb(50, 52, 71)', minHeight: '200vh' }}>
    <div className='App'>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Search />} />
            <Route path="user" element={<UserPage />} />
            <Route path="Favorites" element={<Favorites />} />
            <Route path="Inquiries" element={<Inquiries />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;