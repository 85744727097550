import React from 'react';
import './Inquiries.css'

export default function Inquiries() {
  return (
    <div className="inquiry">
      <iframe
        title="Inquiries Form"
        src="https://docs.google.com/forms/d/e/1FAIpQLSdmbRwaanm1n4TTkefbUKmi0mFdXJMbNelGFjlVhIClP1Anfg/viewform?embedded=true"
        width="640"
        height="876"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
      >
        로드 중…
      </iframe>
    </div>
  );
}